<template>
     <div class="history_detail">
          <div class="his_con">
               <div class="layer1_on flex_row_start_center">
                    <span class="txt1" @click="toDetail('/prototype/optimize/index')">{{L['型号转换']}}</span>
                    <span class="el-icon-arrow-right"></span>
                    <span class="txt1" @click="toDetail('/prototype/optimize/history')">{{L['历史记录']}}</span>
                    <span class="el-icon-arrow-right"></span>
                    <span class="txt2">{{L['详情']}}</span>
               </div>
               <div class="layer2_de">

                    <div class="process flex_row_center_center">
                         <div class="process_item flex_column_center_center" :class="{ state_achieved: true }">
                              <span class="iconfont iconduihao1"></span>
                              <span class="process_state">{{L['发起申请']}}</span>
                         </div>
                         <div class="process_line" :class="{ state_line: true }"></div>


                         <template v-if="demDetail.data.subState < 50">
                              <div class="process_item flex_column_center_center"
                                   :class="{ state_achieved: demDetail.data.subState >= 20 }">
                                   <span class="iconfont iconduihao1"></span>
                                   <span class="process_state">{{L['商务审核通过']}}</span>
                              </div>
                              <div class="process_line" :class="{ state_line: demDetail.data.subState >= 20 }"></div>
                              <div class="process_item flex_column_center_center"
                                   :class="{ state_achieved: demDetail.data.subState >= 30 }">
                                   <span class="iconfont iconduihao1"></span>
                                   <span class="process_state">{{L['客服处理']}}</span>
                              </div>
                              <div class="process_line" :class="{ state_line: demDetail.data.subState >= 30 }"></div>
                              <div class="process_item flex_column_center_center"
                                   :class="{ state_achieved: demDetail.data.subState >= 40 }">
                                   <span class="iconfont iconduihao1"></span>
                                   <span class="process_state">{{L['商务确认']}}</span>
                              </div>
                         </template>

                         <template v-if="demDetail.data.subState >= 50">
                              <div class="process_item flex_column_center_center"
                                   :class="{ state_achieved: demDetail.data.subState >= 50 }">
                                   <span class="iconfont iconduihao1"></span>
                                   <span class="process_state">{{L['已关闭']}}</span>
                              </div>
                         </template>


                    </div>



                    <div class="layer2_top flex_column_center_center">
                         <img class="img2" src="../../../assets/prototype/sketch_pro.png"
                              v-if="demDetail.data.state == 0 || demDetail.data.state == 1" />
                         <img class="img2" src="../../../assets/prototype/sketch_complete.png"
                              v-else-if="demDetail.data.state == 2" />
                         <img class="img2" src="../../../assets/prototype/state_closed.png" v-else />
                         <span class="word11">{{ demDetail.data.stateValue }}</span>
                    </div>

                    <div class="layer2_top flex_column_center_center" v-if="demDetail.data.state == 3">
                         <span class="word12" v-if="demDetail.data.refuseReason">{{L['拒绝原因：']}}{{
                              demDetail.data.refuseReason
                         }}</span>
                         <span class="word12" v-if="demDetail.data.rejectReason">{{L['关闭理由：']}}{{
                              demDetail.data.rejectReason
                         }}</span>

                    </div>



                    <div class="layer2_bottom">
                         <div class="section1 flex_row_between_center">
                              <span class="txt6">{{L['需求编号：']}}{{ demDetail.data.demandSn }}</span>
                              <span class="txt6">{{L['提交时间：']}}{{ demDetail.data.createTime }}
                              </span>
                         </div>
                         <div class="section2 flex-row">
                              <div class="sec_left flex-col">
                                   <div class="left_top">
                                        <div class="info1">
                                             <span class="info_txt1">{{L['供应商信息']}}</span>
                                        </div>
                                        <div class="info1">
                                             <span class="info_txt2">{{L['供应商：']}}</span>
                                             <span class="info_txt3">{{ demDetail.data.storeName }}</span>
                                        </div>
                                        <div class="info1">
                                             <span class="info_txt2">{{L['商务：']}}</span>
                                             <span class="info_txt3">{{ demDetail.data.vendorName || '--' }}
                                                  {{
                                                       demDetail.data.vendorMobile ? demDetail.data.vendorMobile : '--'
                                                  }}</span>
                                        </div>
                                        <div class="info1">
                                             <span class="info_txt2">{{L['客服：']}}</span>
                                             <span class="info_txt3">{{ demDetail.data.serviceName || '--' }}
                                                  {{
                                                       demDetail.data.serviceMobile ? demDetail.data.serviceMobile : '--'
                                                  }}</span>
                                        </div>
                                   </div>
                                   <div class="left_bottom">
                                        <div class="info1">
                                             <span class="info_txt1">{{L['处理记录']}}</span>
                                        </div>
                                        <div class="progress_content">
                                             <div class="progress_content_item"
                                                  v-for="(item, index1) in demDetail.data.logList" :key="index1">
                                                  <div class="circle_icon_wrap">
                                                       <div :class="{ dots: true, cur: index1 == 0 }"></div>
                                                       <div class="progress_line"></div>
                                                  </div>
                                                  <div class="progress_text"
                                                       :class="{ progress_text_active: index1 == 0, foldPadd: isFold }">
                                                       <div class="title">{{ item.logContent || item.recordContent }}
                                                       </div>
                                                       <div class="journal_time">{{L['时间：']}}{{
                                                            item.logTime || item.recordTime
                                                       }}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div class="sec_right">
                                   <div class="info2_con">
                                        <div class="info1">
                                             <span class="info_txt1">{{L['需求信息']}}</span>
                                        </div>
                                        <div class="info1 flex-row">
                                             <div class="info_txt2">{{L['需求描述：']}}</div>
                                             <span class="info_txt3">{{ demDetail.data.description || '--' }}</span>
                                        </div>
                                        <div class="info1 flex-row">
                                             <span class="info_txt2">{{L['原始文件：']}}</span>
                                             <div v-if="demanFileList.length">
                                                  <div class="layer_success flex_row_start_end"
                                                       v-for="(item, index) in demanFileList" :key="index">
                                                       <div class="success_excel flex_row_between_center"
                                                            v-if="item.type == 2">
                                                            <div class="left flex_row_start_center">
                                                                 <img src="../../../assets/prototype/excel.png" alt="">
                                                                 <span>{{L['选型清单']}}</span>
                                                            </div>
                                                       </div>
                                                       <div class="success_img" v-else>
                                                            <div class="img_con"
                                                                 :style="{ backgroundImage: 'url(' + item.file + ')' }">
                                                            </div>
                                                       </div>
                                                       <span v-if="item.type == 2" class="option2"
                                                            @click="previewFile(item)">{{L['预览文件']}}>></span>
                                                       <span class="option2"
                                                            @click="downFile('demandFile', item.type, index)">{{
                                                                 item.type == 2 ? L['下载原始文件'] : L['打开图片']
                                                            }}>></span>
                                                  </div>
                                             </div>
                                             <div v-else>
                                                  <span>--</span>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="info_table">
                                        <div class="info1 flex-row" v-if="demDetail.data.feedbackType == 2">
                                             <span class="info_txt2">{{L['反馈结果：']}}</span>

                                             <div>
                                                  <div class="layer_success flex_row_start_end"
                                                       v-for="(item1, index1) in feedbackFileList" :key="index1">
                                                       <div class="success_excel flex_row_between_center"
                                                            v-if="item1.type == 2">
                                                            <div class="left flex_row_start_center">
                                                                 <img src="../../../assets/prototype/excel.png" alt="">
                                                                 <span>{{L['型号转换反馈文件']}}</span>
                                                            </div>
                                                       </div>
                                                       <div class="success_img" v-else>
                                                            <div class="img_con"
                                                                 :style="{ backgroundImage: 'url(' + item1.file + ')' }">
                                                            </div>
                                                       </div>
                                                       <span class="option2"
                                                            @click="downFile('feedbackFile', item1.type, index1)">{{
                                                                 item1.type == 2 ? L['下载反馈文件'] : L['打开图片']
                                                            }}>></span>
                                                  </div>
                                             </div>
                                        </div>


                                        <div class="info1 flex-row" v-if="demDetail.data.feedbackUrl">
                                             <span class="info_txt2">{{L['反馈结果：']}}</span>

                                             <div>
                                                  <div class="layer_success flex_row_start_end">
                                                       <div class="success_excel flex_row_between_center">
                                                            <div class="left flex_row_start_center">
                                                                 <img src="../../../assets/prototype/excel.png" alt="">
                                                                 <span>{{L['反馈清单']}}</span>
                                                            </div>
                                                       </div>
                                                       <span class="option2"
                                                            @click="downFile('feedbackUrl', 'feedback')">{{L['下载反馈文件']}}>></span>
                                                  </div>
                                             </div>
                                        </div>

                                        <div class="info1 flex-row" v-if="demDetail.data.remark">
                                             <div class="info_txt2">{{L['反馈备注：']}}</div>
                                             <span class="info_txt3">{{ demDetail.data.remark }}</span>
                                        </div>

                                        <div class="table"
                                             v-if="demDetail.data.goodsList && demDetail.data.goodsList.length">
                                             <el-table ref="multipleTable" :data="demDetail.data.goodsList"
                                                  style="width: 100%" @selection-change="handleSelectionChange">
                                                  <el-table-column type="selection" width="45" />
                                                  <el-table-column property="demandGoods" :label="L['需求产品']" width="270"
                                                       :formatter="goodsFormat" />
                                                  <el-table-column :label="L['推荐产品']" width="210">
                                                       <template #default="scope">
                                                            <div style="color: #333333;">
                                                                 {{ scope.row.goodsName }}</div>
                                                            <div style="color: #999;">
                                                                 {{ scope.row.specValues }}</div>
                                                            <div style="color: #999;" v-if="scope.row.orderCode">
                                                                 {{L['订货编码：']}}{{ scope.row.orderCode }}</div>
                                                       </template>
                                                  </el-table-column>
                                                  <el-table-column :label="L['价格']" width="140" align="center">
                                                       <template #default="scope">
                                                            <div v-if="scope.row.productPrice">
                                                                 {{ scope.row.productPrice.toFixed(2) }}</div>
                                                            <div class="need_inquire type2" style="margin: 0 auto;"
                                                                 v-else></div>
                                                       </template>
                                                  </el-table-column>
                                                  <el-table-column :label=" L['数量']" align="center">
                                                       <template #default="scope">
                                                            <div class="goods_edit_nem flex_row_center_center">
                                                                 <div v-if="!scope.row.edit">
                                                                      <span>{{ scope.row.productNum }}</span>
                                                                      <span class="iconfont iconbianji"
                                                                           @click="forEdit('open', scope.row)"></span>
                                                                 </div>
                                                                 <div v-else>
                                                                      <input type="number"
                                                                           @input="forEdit('edit', scope.row)"
                                                                           v-model="scope.row.productNum"
                                                                           @blur="forEdit('close', scope.row)">
                                                                 </div>
                                                            </div>
                                                       </template>
                                                  </el-table-column>
                                             </el-table>



                                             <div class="options_con flex_row_start_center">
                                                  <div class="option1" @click="batchAdd('addCart')">{{L['加入购物车']}}</div>
                                                  <div class="option1" @click="batchAdd('sampleCart')"
                                                       v-if="memberInfo.memberType == 2 || (memberInfo.memberType == 3 && memberInfo.enterpriseMaster != 1)">
                                                       {{L['加入样品申请单']}}
                                                  </div>
                                             </div>
                                        </div>


                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
export default {
     setup() {
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const route = useRoute()
          const store = useStore()
          const memberInfo = ref(store.state.memberInfo);
          const router = useRouter()
          const demandId = ref(0)
          const demDetail = reactive({ data: {} })
          const checkList = ref([])
          const demanFileList = ref([])
          const feedbackFileList = ref([])
          const getDetail = () => {
               proxy.$get('v3/goods/front/goods/demand/detail', { demandId: demandId.value }).then(res => {
                    if (res.state == 200) {
                         demDetail.data = res.data
                         let imgs = ['jpg', 'png', 'jpeg']

                         demanFileList.value = demDetail.data.demandFile.split(',')
                         demanFileList.value = demanFileList.value.map(item => {
                              let items = {
                                   file: item,
                                   type: imgs.includes(item.substring(item.lastIndexOf('.') + 1)) ? 1 : 2
                              }

                              return items
                         })


                         if (demDetail.data.feedbackType == 2) {
                              feedbackFileList.value = demDetail.data.feedbackFile.split(',')
                              feedbackFileList.value = feedbackFileList.value.map(item => {
                                   let items = {
                                        file: item,
                                        type: imgs.includes(item.substring(item.lastIndexOf('.') + 1)) ? 1 : 2
                                   }

                                   return items
                              })
                         }

                         demDetail.data.goodsList && demDetail.data.goodsList.map(item => {
                              item.edit = false
                         })
                    }
               })
          }

          const formatter = (row, column, cellValue, index) => {
               return cellValue.toFixed(2)
          }

          const goodsFormat = (row, column, cellValue, index) => {
               return cellValue ? cellValue : '--'
          }

          const downFile = (type, suffix, index) => {

               if (type == 'feedbackUrl') {
                    let fileName = `${L['反馈清单']}`
                    const aLink = document.createElement('a');
                    document.body.appendChild(aLink);
                    aLink.style.display = 'none';
                    aLink.href = demDetail.data.feedbackUrl
                    aLink.setAttribute('download', fileName);
                    aLink.click();
                    document.body.removeChild(aLink);
               } else {
                    if (suffix == 2) {
                         let fileName = type == 'demandFile' ? `${L['选型清单']}${demDetail.data.demandSn}` : `${L['型号转换反馈文件']}${demDetail.data.demandSn}`
                         const aLink = document.createElement('a');
                         document.body.appendChild(aLink);
                         aLink.style.display = 'none';
                         aLink.href = type == 'demandFile' ? demanFileList.value[index].file : feedbackFileList.value[index].file
                         aLink.setAttribute('download', fileName);
                         aLink.click();
                         document.body.removeChild(aLink);
                    } else {
                         window.open(type == 'demandFile' ? demanFileList.value[index].file : feedbackFileList.value[index].file, '_blank')
                    }

               }
          }

          const previewFile = (item) => {
               localStorage.setItem('previewFile', encodeURIComponent(item.file));
               let newWin = router.resolve('/previewFile')
               window.open(newWin.href, '_blank');
          }

          const forEdit = (type, scoItem) => {
               if (type == 'open') {
                    scoItem.edit = true
               } else if (type == 'edit') {
                    if (!/^[1-9]\d*$/.test(scoItem.productNum) && scoItem.productNum) {
                         scoItem.productNum = scoItem.minBuyNum
                    }
               } else {
                    if (!/^[1-9]\d*$/.test(scoItem.productNum) && scoItem.productNum) {
                         scoItem.productNum = scoItem.minBuyNum
                    } else if (scoItem.productNum <= scoItem.minBuyNum) {
                         scoItem.productNum = scoItem.minBuyNum
                    } else if (scoItem.productNum >= 99999) {
                         scoItem.productNum = 99999
                    }

                    checkList.value.map(i => {
                         if (i.productId == scoItem.productId) {
                              i.number = scoItem.productNum
                         }
                    })

                    scoItem.edit = false
               }
          }

          const batchAdd = (type) => {
               if (!checkList.value.length) {
                    ElMessage(L['请选择产品'])
                    return
               }
               let unNum = 0

               checkList.value.forEach(el => {
                    if (!/^[1-9]\d*$/.test(el.number)) {
                         unNum++
                    }
               });

               if (unNum > 0) {
                    ElMessage(L['请输入非0正整数'])
                    return
               }

               if (type == 'addCart') {
                    proxy.$post('v3/business/front/cart/batchAdd', checkList.value, 'json').then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                         } else {
                              ElMessage(res.msg)
                         }
                    })
               } else {
                    proxy.$post('v3/business/front/sampleCart/batchAdd', checkList.value, 'json').then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                         } else {
                              ElMessage(res.msg)
                         }
                    })
               }
          }

          const toDetail = (path) => {
               router.push(path)
          }

          const handleSelectionChange = (selec) => {
               if (selec.length) {
                    checkList.value = selec.map(item => {
                         return {
                              number: item.productNum,
                              productId: item.productId
                         }
                    })
               } else {
                    checkList.value = []
               }
          }


          onMounted(() => {
               demandId.value = route.query.demandId
               getDetail()
          })
          return {
               tableData: ref([
                    {
                         goodsSpec: '“J”RM2012-0.5W-xxx-±0.02%-±6ppm/℃',
                         goodsName: L['片式合金箔固定电阻器'],
                         price: '¥ 100.00',
                         num: 10
                    }
               ]),
               demDetail,
               downFile,
               previewFile,
               forEdit,
               handleSelectionChange,
               batchAdd,
               toDetail,
               memberInfo,
               demanFileList,
               feedbackFileList,
               formatter,
               goodsFormat,
               L
          }
     }
}
</script>
<style lang="scss">
.table {
     .has-gutter {
          background: #F4F4F4 !important;
     }

     .el-table thead tr {
          background-color: #F4F4F4;
     }

     .el-table th {
          background-color: #F4F4F4;
     }
}


.process {
     margin-bottom: 60px;

     .process_item {
          position: relative;
          margin: 0 5px;
          height: 60px;

          .iconduihao1 {
               font-size: 32px;
               color: #878787;
          }

          .process_state {
               position: absolute;
               font-size: 16px;
               font-family: SourceHanSansCN-Medium;
               line-height: 24px;
               color: #878787;
               white-space: nowrap;
               bottom: -11px;
          }

          &.state_achieved {
               .iconduihao1 {
                    color: $colorMain;
               }

               .process_state {
                    color: #000000;
               }

          }
     }

     .process_line {
          width: 200px;
          height: 0px;
          border: 1px solid #878787;
          opacity: 0.3;

          &.state_line {
               border: 1px solid $colorMain !important;
               opacity: 1;

          }
     }
}

.progress_content {
     margin-top: 20px;
     background-color: #fff;

     .progress_content_item {
          position: relative;
          display: flex;
          align-items: flex-start;

          .progress_date_wrap {
               margin-right: 10px;
               display: flex;
               flex-direction: column;
               align-items: flex-end;
               white-space: nowrap;

               .progress_date {
                    font-weight: 600;
                    font-size: 14px;
                    color: #949494;
               }

               .progress_time {
                    font-size: 14px;
                    color: #949494;
               }
          }

          .circle_icon_wrap {
               width: 12px;
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               margin-right: 20px;
               position: absolute;
               top: 0;
               height: 100%;

               .dots {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;

                    background: #A9A9A9;

                    &.cur {

                         background: #3282FF !important;
                    }
               }

               .progress_line {
                    height: 100%;
                    width: 2px;
                    top: 0;
                    background: #DDDDDD;
                    margin-right: 0px;
               }
          }

          .progress_text {
               white-space: pre-wrap;
               font-size: 14px;
               color: $colorH;
               font-weight: 500;
               /* width: 520px; */
               margin-left: 20px;

               .title {
                    font-weight: 600;
                    word-break: break-all;
                    margin-bottom: 10px;
               }

               .journal_time {
                    margin-bottom: 20px;
                    word-break: break-all;

               }

               &.foldPadd {
                    padding-bottom: 0 !important;
               }

               .remark,
               .address {
                    margin-top: 10px;
                    display: flex;
                    word-break: break-all;
                    font-size: 14px !important;
                    color: $colorH;

                    view:first-child {

                         white-space: nowrap;

                    }
               }
          }

          .progress_text_active {
               font-size: 14px;
               font-family: PingFang SC;
               font-weight: 500;
               color: #3282FF;
          }
     }
}


.history_detail {
     background: #F4F4F4;
     padding-top: 20px;
     padding-bottom: 40px;

     .his_con {
          width: 1200px;
          margin: 0 auto;

          .layer1_on {
               height: 54px;
               background: #FFFFFF;
               padding-left: 32px;

               .txt1 {
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #999;
                    margin-right: 5px;
                    cursor: pointer;

                    &:hover {
                         color: $colorMain;
                    }
               }

               .txt2 {
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #000000;
                    margin-left: 5px;
               }
          }

          .layer2_de {
               padding: 35px;
               background-color: #fff;
               margin-top: 10px;

               .layer2_top {
                    margin-bottom: 27px;

                    .img2 {
                         width: 72px;
                         height: 72px;
                    }

                    .word11 {
                         color: rgba(0, 0, 0, 0.85);
                         font-size: 24px;
                         margin-top: 27px;
                    }

                    .word12 {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         line-height: 20px;
                         color: #666666;
                         margin-bottom: 10px;
                    }


               }

               .layer2_bottom {
                    border: 1px solid #DDDDDD;

                    .section1 {
                         padding: 0 20px;
                         background: #FAFAFA;
                         border-bottom: 1px solid #DDDDDD;
                         height: 40px;

                         .txt6 {
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                              font-weight: 400;
                              color: #333333;
                         }
                    }

                    .section2 {
                         .sec_left {
                              width: 265px;

                              border-right: 1px solid #DDDDDD;

                              .left_top {
                                   padding-left: 20px;
                                   padding-top: 30px;
                                   border-bottom: 1px solid #DDDDDD;

                              }

                              .left_bottom {
                                   padding: 20px;
                              }
                         }

                         .sec_right {
                              padding: 30px 20px;
                              width: 860px;

                              .goods_edit_nem {

                                   .iconbianji {
                                        margin-left: 2px;
                                        color: $colorH;
                                        cursor: pointer;
                                   }

                                   input {
                                        width: 30px;
                                        border: none;
                                        text-align: center;
                                        line-height: 30px;
                                   }

                                   /*** 消除input元素 type="number" 时默认的 加减按钮*/
                                   input[type="number"]::-webkit-inner-spin-button,
                                   input[type="number"]::-webkit-outer-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0;
                                   }

                                   /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
                                   input[type="number"] {
                                        -moz-appearance: textfield;
                                   }
                              }
                         }

                         .info1 {
                              margin-bottom: 21px;



                              .info_txt1 {
                                   font-size: 16px;
                                   font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                                   font-weight: 500;
                                   color: #333333;
                              }

                              .info_txt2 {
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                              }

                              .info_txt3 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   word-break: break-all;
                                   display: inline-block;
                                   max-width: 710px;
                                   line-height: 20px;
                              }

                              .layer_success {
                                   margin-bottom: 10px;

                                   .success_excel {
                                        height: 62px;
                                        border-radius: 2px;
                                        background: $colorK;
                                        width: 260px;
                                        padding-left: 10px;

                                        .left {
                                             img {
                                                  width: 42px;
                                                  height: 42px;
                                             }

                                             span {
                                                  margin-left: 15px;
                                                  font-size: 14px;
                                                  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                                  font-weight: 400;
                                                  color: #333333;
                                             }
                                        }

                                        .right {
                                             display: flex;
                                             align-self: flex-start;
                                             padding-top: 5px;
                                             padding-right: 5px;

                                             .right_con {
                                                  cursor: pointer;
                                                  width: 18px;
                                                  height: 18px;
                                                  background: #999;
                                                  border-radius: 50%;
                                                  text-align: center;
                                                  line-height: 18px;
                                                  align-self: flex-start;

                                                  .el-icon-delete {
                                                       color: #fff;
                                                  }
                                             }

                                        }
                                   }

                                   .success_img {
                                        position: relative;
                                        width: 179px;
                                        height: 179px;
                                        background: #FFFFFF;
                                        border-radius: 2px;
                                        border: 1px solid #D9D9D9;

                                        .img_con {
                                             width: 179px;
                                             height: 179px;
                                             background-repeat: no-repeat;
                                             background-position: center center;
                                             background-size: cover;
                                        }
                                   }
                              }

                              .option2 {
                                   color: $colorMain;
                                   border-bottom: 1px solid $colorMain;
                                   font-size: 14px;
                                   margin-left: 10px;
                                   cursor: pointer;
                              }
                         }

                         .options_con {
                              margin-top: 30px;

                              .option1 {
                                   color: #fff;
                                   font-size: 14px;
                                   font-family: PingFangSC-Regular, PingFang SC;
                                   font-weight: 400;
                                   padding: 10px 15px;
                                   background: $colorMain;
                                   margin-right: 20px;
                                   cursor: pointer;
                              }
                         }

                    }
               }
          }
     }
}
</style>